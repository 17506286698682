import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Obtenha o token do armazenamento local ou de onde você o armazena
        const token = localStorage.getItem('token');
        // Verifique se a rota contém "login" ou "cadastro"
        if (
            !request.url.includes('login') &&
            !request.url.includes('cadastro') 
        ) {
            // Clone a solicitação e adicione o token ao cabeçalho Authorization
            if (token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            }
        }

        // Continue com a solicitação modificada
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // Limpe o localStorage
                    localStorage.clear();
                    this.router.navigate(['/login']);
                    setTimeout( () => location.reload() , 500);
                    // Navegue para a página de login
                }

                return throwError(error);
            })
        );
    }
}
