import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Cadastro } from '../../../classes/cadastro';
import { environment } from '../../../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import * as $ from 'jquery';
import { Observable } from 'rxjs';

@Component({
    selector: 'cadastro-component',
    templateUrl: './cadastro.component.html',
    styleUrls: ['./cadastro.component.scss']
})
export class CadastroComponent implements OnInit {

    mostrarSenha: boolean = false;
    mostrarSenhaC: boolean = false;
    isEmailDisabled: boolean = false;
    step: number = -1;
    pessoa: string = 'fisica';
    cadastro: Cadastro = new Cadastro();
    invalid: boolean = false;
    msg_invalid: string = '';
    validacao: boolean = false;
    erro_formato_email: boolean = false;
    carregando: boolean = false;
    regulamento: boolean = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private rota: ActivatedRoute,
        private auth: AuthService
    ) {}

    ngOnInit() {
        this.rota.queryParams.subscribe(params => {
            if (params['email']) {
                this.cadastro.email = params['email'];
                this.isEmailDisabled = true;
                setTimeout(() => {
                    this.atualizarCampoEmailNome(params['email'], params['nome']);
                }, 1000);
            }
        });

        $('.nav-section-main-mobile').removeClass('active-menu');
        this.cadastro = new Cadastro();
        const id_indicador = this.rota.snapshot.paramMap.get('id');
        if (id_indicador) {
            this.cadastro.id_indicador = id_indicador;
        }
    }

    atualizarCampoEmailNome(email: string, nome: string) {
        this.cadastro.email = email;
        this.cadastro.nome = nome;
    }


    step0() {
        this.invalid = false;
        this.validacao = true;
        this.carregando = true;
    
        if (!this.validarCamposObrigatorios()) {
            this.carregando = false;
            return;
        }
    
        if (!this.validarDataAniversario()) {
            this.carregando = false;
            return;
        }
    
        var self = this;
        this.verificarEmailECpf().subscribe({
            next: function(result: any) {
                if (result.error) {
                    self.set_invalid(result.error);
                    self.carregando = false; // Para garantir que o carregamento pare se houver um erro
                } else {
                    self.set_step(0);
                }
            },
            error: function(errorMsg: string) {
                self.set_invalid(errorMsg);
                self.carregando = false; // Pare o carregamento em caso de erro
            },
            complete: function() {
                self.carregando = false;
            }
        });
    }

    private validarCamposObrigatorios(): boolean {
        if (!this.cadastro.cpf || !this.validarCPF(this.cadastro.cpf)) {
            this.set_invalid('Verifique o preenchimento, o CPF parece inválido.');
            return false;
        }

        if (!this.cadastro.cnpj || !this.validarCNPJ(this.cadastro.cnpj)) {
            this.set_invalid('Verifique o preenchimento, o CNPJ parece inválido.');
            return false;
        }

        if (!this.cadastro.nome || this.cadastro.nome.length < 4 || this.cadastro.nome.split(' ').length < 2) {
            this.set_invalid('Verifique o preenchimento, o campo NOME é obrigatório e deve ser completo.');
            return false;
        }

        if (!this.cadastro.email || !this.validarFormatoEmail(this.cadastro.email)) {
            this.set_invalid('Verifique o formato do EMAIL utilizado.');
            return false;
        }

        if (!this.cadastro.celular || this.cadastro.celular.length < 10) {
            this.set_invalid('Verifique o preenchimento, o campo Whatsapp é obrigatório.');
            return false;
        }

        return true;
    }

    private validarFormatoEmail(email: string): boolean {
        return email.includes('@') && email.includes('.') && email.length >= 6;
    }

    private validarDataAniversario(): boolean {
        if (this.cadastro.aniversario.length < 8) {
            this.set_invalid('Verifique o preenchimento, o campo ANIVERSÁRIO é obrigatório.');
            return false;
        }

        const dataAtual = new Date();
        const mes = parseInt(this.cadastro.aniversario.substring(2, 4));
        const ano = parseInt(this.cadastro.aniversario.substring(4, 8));
        if (mes > 12 || mes === 0) {
            this.set_invalid('Verifique o formato do campo ANIVERSÁRIO.');
            return false;
        }

        const idade = dataAtual.getFullYear() - ano - 
            (dataAtual.getMonth() < mes - 1 || 
            (dataAtual.getMonth() === mes - 1 && 
            dataAtual.getDate() < parseInt(this.cadastro.aniversario.substring(0, 2))) ? 1 : 0);

        if (idade < 18) {
            this.set_invalid('Você precisa ter +18 anos para se cadastrar.');
            return false;
        }

        return true;
    }
    
    private verificarEmailECpf() {
        var self = this;
    
        return new Observable(observer => {
            self.verificarEmail().subscribe(
                function(emailData: any) {
                    if (emailData.status === 1) {
                        observer.next({ error: 'E-mail já cadastrado no sistema' });
                        observer.complete();
                    } else {
                        self.verificarCpf().subscribe(
                            function(cpfData: any) {
                                if (cpfData.status === 1) {
                                    observer.next({ error: 'CPF já cadastrado' });
                                } else {
                                    observer.next({});
                                }
                                observer.complete();
                            },
                            function() {
                                observer.next({ error: 'Erro ao verificar CPF. Tente novamente mais tarde.' });
                                observer.complete();
                            }
                        );
                    }
                },
                function() {
                    observer.next({ error: 'Erro ao verificar e-mail. Tente novamente mais tarde.' });
                    observer.complete();
                }
            );
        });
    }
    
    private verificarEmail() {
        return this.http.post<any>(environment.API_URL + 'cadastro/obter_email/', { email: this.cadastro.email });
    }
    
    private verificarCpf() {
        return this.http.get<any>(environment.API_URL + 'cadastro/obter_cpf/' + this.cadastro.cpf);
    }
    
    step1() {
        this.carregando = true;
        this.invalid = false;
        this.validacao = true;

        if (!this.validarEndereco()) {
            this.carregando = false;
            return;
        }

        this.validacao = false;
        $('html, body').animate({ scrollTop: 0 }, 500);
        this.step = 1;
        this.carregando = false;
    }

    private validarEndereco(): boolean {
        if (this.cadastro.rua.length < 3) {
            this.set_invalid('Verifique o preenchimento, o campo RUA é obrigatório.');
            return false;
        }

        if (!this.cadastro.numero) {
            this.set_invalid('Verifique o preenchimento, o campo NÚMERO é obrigatório.');
            return false;
        }

        if (this.cadastro.cep.length < 8) {
            this.set_invalid('Verifique o preenchimento, o campo CEP é obrigatório.');
            return false;
        }

        if (this.cadastro.cidade.length < 3) {
            this.set_invalid('Verifique o preenchimento, o campo CIDADE é obrigatório.');
            return false;
        }

        if (this.cadastro.estado.length < 2) {
            this.set_invalid('Verifique o preenchimento, o campo ESTADO é obrigatório.');
            return false;
        }

        if (this.cadastro.bairro.length === 0) {
            this.set_invalid('Verifique o preenchimento, o campo BAIRRO é obrigatório.');
            return false;
        }

        return true;
    }

    step2() {
        this.carregando = true;
        this.invalid = false;
        this.validacao = true;
    
        if (!this.validarSenha(this.cadastro.senha)) {
            this.carregando = false;
            return;
        }
    
        if (!this.cadastro.aceito_term) {
            this.set_invalid('Você precisa aceitar o regulamento para prosseguir');
            this.carregando = false;
            return;
        }
    
        this.http.post<any>(`${environment.API_URL}cadastro/cadastrar`, this.cadastro).subscribe(
            data => {
                this.carregando = false;
                if (data.status === 1) {
                    this.guardarDadosUsuario(data);
                    this.router.navigate(['/home']).then(() => {
                        window.location.reload();
                    });
                } else {
                    this.set_invalid('Ops! Houve um erro ao processar essa requisição, tente novamente mais tarde.');
                }
            },
            error => {
                this.carregando = false;
                this.set_invalid('Ops! Houve um erro ao processar essa requisição, tente novamente mais tarde.');
            }
        );
    
        this.validacao = false;
        $('html, body').animate({ scrollTop: 0 }, 500);
    }
    

    private guardarDadosUsuario(data: any) {
        localStorage.setItem('userId', data.id);
        localStorage.setItem('userName', this.cadastro.nome);
        localStorage.setItem('token', data.token);
        localStorage.setItem('tipo', data.tipo);
    }

    set_invalid(msg: string) {
        this.msg_invalid = msg;
        this.invalid = true;
        this.carregando = false;
    }

    set_step(number: number) {
        this.carregando = false;
        this.invalid = false;
        this.msg_invalid = null;
        this.step = number;
    }

    viacep() {
        if (this.cadastro.cep.length < 8) {
            return;
        }

        this.cadastro.rua = 'aguarde...';
        this.http.get<any>(`https://viacep.com.br/ws/${this.cadastro.cep}/json`).subscribe(end => {
            this.cadastro.rua = end.logradouro;
            this.cadastro.complemento = end.complemento;
            this.cadastro.bairro = end.bairro;
            this.cadastro.cidade = end.localidade;
            this.cadastro.estado = end.uf;

            $("#numero").focus().css('border', '1px solid orange');
            $("#complemento").css('border', '1px solid orange');
        });
    }

    validarCPF(cpf: string): boolean {
        if (cpf.length < 11) {
            return false;
        }

        let soma = 0;
        let resto;
        if (cpf === '00000000000') {
            return false;
        }

        for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.substring(9, 10))) {
            return false;
        }

        soma = 0;
        for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if (resto === 10 || resto === 11) resto = 0;
        if (resto !== parseInt(cpf.substring(10, 11))) {
            return false;
        }

        return true;
    }

    validarCNPJ(cnpj: string): boolean {
        cnpj = cnpj.replace(/[^\d]+/g, '');
        if (cnpj.length !== 14) {
            return false;
        }

        if (/^(\d)\1+$/.test(cnpj)) {
            return false;
        }

        let tamanho = cnpj.length - 2;
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2) pos = 9;
        }

        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(digitos.charAt(0))) {
            return false;
        }

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;

        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2) pos = 9;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(digitos.charAt(1))) {
            return false;
        }

        return true;
    }

    validarSenha(senha: string): boolean {
        if (senha.length < 6) {
            this.set_invalid('Sua senha deve ter ao menos 6 caracteres');
            return false;
        }

        if (senha !== this.cadastro.senha_c) {
            this.set_invalid('As senhas não conferem');
            return false;
        }

        if (!/[!@#$%^&*(),.?":{}|<>]/.test(senha)) {
            this.set_invalid('A senha deve ter ao menos 1(um) caractere especial');
            return false;
        }

        if (!/[A-Z]/.test(senha)) {
            this.set_invalid('A senha deve ter ao menos 1(uma) letra maiúscula');
            return false;
        }

        if (!/[0-9]/.test(senha)) {
            this.set_invalid('A senha deve ter ao menos 1(um) número');
            return false;
        }

        return true;
    }

    abrir_regulamento() {
        this.regulamento = true;
    }
}
