import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import LocalePt from '@angular/common/locales/pt';
registerLocaleData(LocalePt, 'pt');

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { CountdownModule } from 'ngx-countdown';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { QRCodeModule } from 'angularx-qrcode';

import { TokenInterceptor } from './token.interceptor';

import { ExtratoComponent } from './modulos/benefits/extrato/extrato.component';
import { DescontosComponent } from './modulos/benefits/descontos/descontos.component';
import { LeilaoComponent } from './modulos/benefits/leilao/leilao.component';
import { ProdutosComponent } from './modulos/benefits/produtos/produtos.component';
import { BoletosComponent } from './modulos/benefits/boletos/boletos.component';
import { CadastroPontosComponent } from './modulos/benefits/cadastro-pontos/cadastro-pontos.component';
import { DecodeHtmlPipe } from 'src/app/pipes/decodeHtml.pipe';
import { FaleConoscoComponent } from './modulos/benefits/fale-conosco/fale-conosco.component';

import { LandingComponent } from './modulos/content/landing/landing.component';
import { RankingComponent } from './modulos/content/ranking/ranking.component'; 
import { FaqComponent } from './modulos/content/faq/faq.component';
import { OngsComponent } from './modulos/content/ongs/ongs.component';  
import { HomeComponent } from './modulos/content/home/home.component';  
import { IndexComponent } from './modulos/content/index/index.component';

import { HeaderComponent } from './modulos/includes/header/header.component';
import { PopupVideoComponent } from './modulos/includes/popup-video/popup-video.component';
import { PerfilComponent } from './modulos/includes/perfil/perfil.component';
import { FooterComponent } from './modulos/includes/footer/footer.component';
import { RegulamentomodalComponent } from './modulos/includes/regulamentomodal/regulamentomodal.component';
import { ContagemRegressivaComponent } from './modulos/includes/contagem_regressiva/contagem_regressiva.component';
import { LoaderComponent } from './modulos/includes/loader/loader.component';
import { HeaderUserComponent } from './modulos/includes/header-user/header-user.component';
import { CardRankingExtratoComponent } from './modulos/includes/card-ranking-extrato/card-ranking-extrato.component';
import { PlacarComponent } from './modulos/includes/placar/placar.component';
import { CadastroCpfComponent } from './modulos/includes/cadastro-cpf/cadastro-cpf.component';
import { RegulamentoSorteioComponent } from './modulos/includes/regulamento-sorteio/regulamentosorteio.component';
import { ModalRegrasComponent } from './modulos/includes/modal-regras/modal-regras.component';
import { BannerPrincipalComponent } from './modulos/includes/banner-principal/banner-principal.component';
import { DoarComponent } from './modulos/includes/doar/doar.component';
import { ModalComponent } from './modulos/includes/modal/modal.component';
import { Qrscan3Component } from './modulos/includes/qrscan3/qrscan3.component';
import { ModalEnderecoComponent } from './modulos/includes/modal-endereco/modal-endereco.component';

import { PerfilPageComponent } from './modulos/profile/perfil/perfil.component';
import { IndiqueComponent } from './modulos/profile/indique/indique.component';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { LoginComponent } from './modulos/auth/login/login.component';
import { ConfirmacaoComponent } from './modulos/auth/confirmacao/confirmacao.component';
import { DescadastrarComponent } from './modulos/auth/descadastrar/descadastrar.component';
import { PrimeiroAcessoComponent } from './modulos/auth/primeiro-acesso/primeiro-acesso.component';
import { CadastroComponent } from './modulos/auth/cadastro/cadastro.component';


@NgModule({
    declarations: [
        AppComponent,

        ConfirmacaoComponent,
        CadastroComponent,
        DescadastrarComponent,
        LoginComponent,
        PrimeiroAcessoComponent,

        PerfilPageComponent,
        IndiqueComponent,

        ExtratoComponent,
        DescontosComponent,
        LeilaoComponent,
        ProdutosComponent,
        BoletosComponent,
        CadastroPontosComponent,
        DecodeHtmlPipe,
        FaleConoscoComponent,

        LandingComponent,
        RankingComponent,
        FaqComponent,
        OngsComponent,
        HomeComponent,
        IndexComponent,


        HeaderComponent,
        PopupVideoComponent,
        PerfilComponent,
        FooterComponent,
        RegulamentomodalComponent,
        ContagemRegressivaComponent,
        LoaderComponent,
        HeaderUserComponent,
        CardRankingExtratoComponent,
        PlacarComponent,
        RegulamentoSorteioComponent,
        CadastroCpfComponent,
        ModalRegrasComponent,
        BannerPrincipalComponent,
        DoarComponent,
        ModalComponent,
        Qrscan3Component,
        ModalEnderecoComponent,
        CadastroCpfComponent

    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        NgbModule,
        NgxUiLoaderModule,
        FontAwesomeModule,
        CurrencyMaskModule,
        CountdownModule,
        AutocompleteLibModule,
        IonicModule.forRoot(),
        NgxMaskModule.forRoot(),
        AppRoutingModule, 
        ZXingScannerModule,
        QRCodeModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production, //enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable'
          })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'pt-BR'},
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },

    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
