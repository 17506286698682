import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  // Método para obter um item do localStorage
  getItem(key: string): any {
    const value = localStorage.getItem(key);
    try {
      // Tenta converter para JSON, caso seja um objeto armazenado
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  // Método para salvar um item no localStorage
  setItem(key: string, value: any): void {
    // Converte o valor para string antes de armazenar
    if (typeof value === 'object') {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  }

  // Método para remover um item do localStorage
  removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  // Método para limpar todo o localStorage
  clear(): void {
    localStorage.clear();
  }

  // Verifica se uma chave existe no localStorage
  exists(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }
}
